<template>
  <div>
    <AppHeader title="传感器事件"/>
    <Panel class="tabs search-panel" :title="false">
      <div slot="content">
        <div class="line">
          <input class="line-input" id="eqName" @click="showEquipments" v-model="equipment"
                 @keydown="preventDefault" placeholder="仪器名称"/>
          <input class="line-input mt10" id="beginAt" @click="showPicker('beginAt')" :value="dateFormat(beginAt)"
                 @keydown="preventDefault" placeholder="开始时间"/>
          <input class="line-input mt10" id="endAt" @click="showPicker('endAt')" :value="dateFormat(endAt)"
                 @keydown="preventDefault" placeholder="结束时间"/>
          <div class="flex-dir-row mt10">
            <div class="flex-item-auto">
              <input class="line-input" id="code" @click="showCodes" :value="code" @keydown="preventDefault" placeholder="传感器代码"/>
            </div>
            <div class="btn btn-small green ml5" @click="reset">重设</div>
            <div class="btn btn-small green ml5" @click="initEvents">查询</div>
          </div>
        </div>
      </div>
    </Panel>
    <Panel class="tabs mt15" :title="false">
      <div slot="content" v-infinite-scroll="loadEvents"
           infinite-scroll-disabled="loading"
           infinite-scroll-distance="10">
        <div class="line" v-for="(se,idx) of sensorEvents">
          <div class="flex-dir-row" style="font-weight: 500">
            <div class="line-label">事件标题：</div>
            {{ se.title }}
          </div>
          <div class="flex-dir-row">
            <div class="line-label">仪器名称：</div>
            {{ se.equipment }}
          </div>
          <div class="flex-dir-row">
            <div class="line-label">传感器代码：</div>
            {{ se.name }}
          </div>
          <div class="flex-dir-row">
            <div class="line-label">事件级别：</div>
            {{ se.level }}
          </div>
          <div class="flex-dir-row">
            <div class="line-label">发生时间：</div>
            {{ se.happenAt }}
          </div>
        </div>
        <div class="line text-center" v-if="sensorEvents.length===0">暂无记录</div>
      </div>
    </Panel>

    <mt-datetime-picker
        v-model="dateVal"
        type="datetime"
        @confirm="handleConfirm"
        ref="picker"
    >
    </mt-datetime-picker>

    <mt-popup
        v-model="codesVisible"
        position="bottom"
    >
      <mt-picker :slots="codeSlots" @change="onCodeChange" v-if="codesVisible"></mt-picker>
    </mt-popup>

    <mt-popup
        v-model="equipmentsVisible"
        position="bottom"
    >
      <mt-picker :slots="equipmentSlots" @change="onEquipmentChange" value-key="text" v-if="equipmentsVisible"></mt-picker>
    </mt-popup>

  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import Panel from "@/components/Panel";
import {InfiniteScroll, DatetimePicker, Picker} from 'mint-ui';
import dict from "@/plugins/dict";
import store from "@/plugins/store";
import {mapState} from 'vuex'

export default {
  components: {
    AppHeader,
    Panel,
    InfiniteScroll,
    DatetimePicker,
    Picker
  },
  data() {
    return {
      page: 1,
      sensorEvents: [],
      loading: false,
      noMore: false,
      beginAt: null,
      endAt: null,
      equipment: null,
      code: null,

      codeSlots: [{values: ['menjin', 'wendu', 'shidu', 'voc', 'gonglv', 'huojing', 'dianyuan', 'action', 'kaiguan', 'pm2.5']}],
      codesVisible: false,

      dateVal: new Date(),
      pickerField: '',

      equipmentsVisible: false,
    }
  },
  computed: mapState({
    // 为了能够使用 `this` 获取局部状态，必须使用常规函数
    equipmentSlots(state) {
      return [{values: state.equipments}]
    }
  }),
  methods: {
    onEquipmentChange(picker, values) {
      this.equipment = values[0].text
      this.equipmentId = values[0].id
    },
    showEquipments() {
      this.equipmentsVisible = true
    },
    onCodeChange(picker, values) {
      this.code = values[0]
    },
    showCodes() {
      this.codesVisible = true
    },

    showPicker(pickerField) {
      this.dateVal = new Date()
      this.pickerField = pickerField
      this.$refs.picker.open()
    },
    handleConfirm(value) {
      if (this.pickerField === 'beginAt') {
        this.beginAt = this.$moment(value).valueOf()
      } else {
        this.endAt = this.$moment(value).valueOf()
      }
    },
    reset() {
      this.page = 1
      this.loading = false
      this.beginAt = null
      this.endAt = null
      this.code = ''
      this.equipment = ''
      this.equipmentId = ''
    },
    dateFormat(timestamp) {
      if (timestamp) {
        return this.$moment(timestamp).format('YY-MM-DD HH:mm')
      }
      return ''
    },
    initEvents() {
      this.sensorEvents = []
      this.page = 1
      this.noMore = false
      this.loadEvents()
    },
    loadEvents() {
      if (this.noMore) {
        return
      }
      const that = this
      this.loading = true
      let data = new FormData()
      data.append('page', `${this.page}`)
      data.append('limit', '20')
      this.equipmentId && data.append('equipmentId', this.equipmentId)
      this.beginAt && data.append('beginAt', `${this.beginAt}`)
      this.endAt && data.append('endAt', `${this.endAt}`)
      this.code && data.append('name', `${this.code}`)

      this.$http.post(`/portal/sensorEvent/query`, data).then(res => {
        if (res.data && res.data.length > 0) {
          that.sensorEvents = that.sensorEvents.concat(res.data)
          that.loading = false
          that.page = that.page + 1
          console.log(that.sensorEvents)
        } else {
          this.noMore = true
        }
      })
    },
    preventDefault(e) {
      e.preventDefault()
    }
  },
  mounted() {
    this.initEvents()
  }
}
</script>